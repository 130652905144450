// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

$primary-font: 'Open Sans', sans-serif;

$my-typography: mat.define-legacy-typography-config(
  $font-family: $primary-font,
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-typography);`
@include mat.all-legacy-component-typographies($my-typography);
@include mat.legacy-core();

@import "variables";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$eshop-primary: mat.define-palette(mat.$indigo-palette);
//$eshop-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$eshop-primary: mat.define-palette($primary-palette);
$eshop-accent: mat.define-palette($accent-palette);
$eshop-warn: mat.define-palette($red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$theme: mat.define-light-theme(
    (
      color: (
        primary: $eshop-primary,
        accent: $eshop-accent,
        warn: $eshop-warn,
      ),
    )
);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($theme);

