@import "swiper/scss";
@import "swiper/scss/free-mode";
@import "swiper/scss/navigation";
@import "swiper/scss/thumbs";
@import "swiper/scss/pagination";

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    background-size: cover;
    background: #fff center;

    img {
      border-radius: 5px;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-pagination-bullet {
    background: white;
    border: 2px solid $secondary-color;
    height: 14px;
    width: 14px;

    &.swiper-pagination-bullet-active {
      background: $secondary-color;
      height: 18px;
      width: 18px;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    color: black;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
}

.main-swiper {
  //height: 80%;
  height: 300px;
  max-width: 480px; //image width
  width: 100%;
}

.thumbnail-wrapper {

  //max-width: 500px; margin: 0 auto;

  .thumbnail-swiper {
    //height: 20%;
    height: auto;
    max-width: 480px; //image width
    //max-height: 80px;
    box-sizing: border-box;
    //padding: 10px 0;

    .swiper-slide {
      width: 20%;
      height: 100%;
      border: solid 1px #e2e2e2;
      //border-style: hidden;
      border-radius: 5px;
      //opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      border: solid 1px #f06724;
    }
  }
}

.fav-icon-wrapper {
  border: 1px solid #54688b;
  border-radius: 4px;
  display: inline-block;
  padding: 3px 3px 0;
  background: white;
  height: 2rem;
}

.img-pills {
  top: 0;
  left: 0;
  font-size: 12px
}

.nav-icon {
  transform: scale(0.7);
}

.swiper-nav-icon {
  //transform: scale(0.7);
  cursor: pointer;
  &.swiper-button-disabled {
    color: #e2e2e2;
    cursor: not-allowed;
  }
}

