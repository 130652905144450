//@import "~node_modules/bootstrap/scss/functions";
//@import "~node_modules/bootstrap/scss/variables";

$blue-palette: (
  50: #e4e7ec,
  100: #e9ebf0,
  200: #c6ccdb,
  300: #8d9bb2,
  400: #54688b,
  500: #1b3664,
  600: #18305c,
  700: #142952,
  800: #102248,
  900: #081636,
  A100: #6f92ff,
  A200: #3c6cff,
  A400: #0945ff,
  A700: #003aef,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$orange-palette: (
  50: #fdede5,
  100: #fef0ea,
  200: #fbd9c8,
  300: #f8b392,
  400: #f48d5b,
  500: #f06724,
  600: #ee5f20,
  700: #ec541b,
  800: #e94a16,
  900: #e5390d,
  A100: #ffffff,
  A200: #ffe3dd,
  A400: #ffb8aa,
  A700: #ffa390,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$red-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #ff0000,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$primary-palette: $blue-palette;
$accent-palette: $orange-palette;
$secondary-palette: $orange-palette;

$primary-color: map-get($primary-palette, 500);
$secondary-color: map-get($secondary-palette, 500);
$secondary-300: map-get($secondary-palette, 300);
$primary-400: map-get($primary-palette, 400);
$error-color: map-get($red-palette, 500);

$max-width: 1366px;
