@import "node_modules/bootstrap/scss/functions";

@import "variables";

$blue: map-get($blue-palette, 500);

$alert-bg-scale: -50%;
$alert-border-scale: -70%;
$alert-color-scale: 30%;

$form-check-input-checked-color: $primary-color;
$form-check-input-checked-bg-color: #fff;
$form-check-input-checked-border-color: $primary-color;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2.5' fill='#{$form-check-input-checked-color}'/></svg>");

//$spacer: 1rem;
//$spacers: (
//  0: 0,
//  1: $spacer * .25,
//  2: $spacer * .5,
//  3: $spacer,
//  4: $spacer * 1.5,
//  5: $spacer * 3,
//  5\.5: $spacer * 3.5,
//);


@import "node_modules/bootstrap/scss/variables";

$spacers: map-merge(
    (
      '2r': ( $spacer * 2 ),
      '1u': ( $spacer * 0.625 ),
      '2u': ( $spacer * 0.625 * 2 ),
      6: ( $spacer * 4 ),
    ),
    $spacers
);


$theme-colors: (
  'primary': map-get($primary-palette, 500),
  'primary-100': map-get($primary-palette, 100),
  'primary-200': map-get($primary-palette, 200),
  'primary-300': map-get($primary-palette, 300),
  'primary-400': map-get($primary-palette, 400),
  'secondary': map-get($secondary-palette, 500),
  'secondary-100': map-get($secondary-palette, 100),
  'secondary-200': map-get($secondary-palette, 200),
  'secondary-300': map-get($secondary-palette, 300),
  'success': $success,
  //'info': $info,
  //'warning': $warning,
  'danger': $error-color,
  //'red': $error-color,
  'light': $light,
  //'dark': $dark,
);
//

$body-bg: #fff;
$body-color: #111;

$breadcrumb-divider: quote(">");

