@use 'sass:map';
@import "stylings/material";
@import "stylings/bootstrap";
@import "stylings/swiper";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: $primary-font;
}

.mat-form-field-underline {
  display: none;
}

.mat-focused {
  .mat-form-field-underline {
    display: block;
  }
}

.alert-primary {
  background-color: rgba($primary-400, 0.5);
  color: $primary;
}

.mt-3\.5 {
  margin-top: 1.25rem;
}
.mt-4\.5 {
  margin-top: 2.69rem;
}

.mt-2r {
  margin-top: 2rem;
}

.mat-drawer-content {
  background-color: white;
}

.mat-bottom-sheet-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mat-dialog-container {
  padding: 0 !important;
  //padding-left: 0 !important;

  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
  }
}

.card-title {
  font-size: 18px;
  font-weight: 600
}

.responsive-dialog {
  width: 100%;

  @media screen and (min-width: map.get($grid-breakpoints, 'md')) {
    width: 750px;
  }
}

.col-rep-1 {
  grid-template-columns: repeat(1, 1fr)
}

.col-rep-2 {
  grid-template-columns: repeat(2, 1fr)
}

.col-rep-3 {
  grid-template-columns: repeat(3, 1fr)
}


.gap-col-3 {
  column-gap: 1rem;
}

.col-rep-md-2 {
  @media screen and (min-width: map.get($grid-breakpoints, 'md')) {
    grid-template-columns: repeat(2, 1fr)
  }
}

.col-rep-lg-3 {
  @media screen and (min-width: map.get($grid-breakpoints, 'lg')) {
    grid-template-columns: repeat(3, 1fr)
  }
}

.shadow-actions.mat-dialog-actions {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);

  button {
    width: 90px
  }
}

.cur-pointer {
  cursor: pointer;
}

.flex-basis-0 {
  //flex-basis: 0;
}

.loading-spinner:not(:disabled) {
  .spinner-border {
    display: none;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.bordered-icon {
  display: inline-flex;
  border: 1px solid #e2e2e2;
  border-radius: 5px;

  .icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
  }
}

// =============================================
// fonts
.fs-2xs {
  font-size: 0.625rem;// 10px;
}

.fs-xs {
  font-size: 0.75rem;// 12px;
}

.fs-s {
  font-size: 0.875rem;// 14px;
}

.fs-l {
  font-size: 1.125rem !important;// 18px;
}

.fs-xl {
  font-size: 1.25rem !important;// 20px;
}

.fs-2xl {
  font-size: 1.375rem;// 22px;
}

.fs-3xl {
  font-size: 1.5rem;// 24px;
}

.fs-inh {
  font-size: inherit;
}

@media screen and (min-width: map.get($grid-breakpoints, 'lg')) {
  .fs-lg-l {
    font-size: 1.125rem !important;// 18px;
  }
}
@media screen and (min-width: map.get($grid-breakpoints, 'md')) {
  .fs-md-s {
    font-size: 0.875rem !important;// 14px;
  }
  .fs-md-l {
    font-size: 1.125rem !important;// 18px;
  }
  .fs-md-3xl {
    font-size: 1.5rem !important;
  }
}
@media screen and (min-width: map.get($grid-breakpoints, 'sm')) {
  .fs-sm-s {
    font-size: 0.875rem !important;// 14px;
  }
  //.fs-sm-3xl {
  //  font-size: 1.5rem !important;// 22px;
  //}
  //.fs-sm-2xl {
  //  font-size: 1.375rem !important;// 22px;
  //}
}



.h-inh {
  height: inherit;
}

.w-inh {
  width: inherit;
}

.mat-bottom-sheet-container {
  .dialog-header {
    padding: 1rem;
  }
}

.divider-light {
  border-top-color: #f0f0f0;
}

.text-black-87 {
  color: rgba(0, 0, 0, 0.87);
}

.zaidra-snack-bar {
  color: rgba(0, 0, 0, 0.87) !important;
  background: white !important;

  &.mat-warn {
    border: 2px solid red;
  }
  &.mat-primary {
    border: 2px solid $primary-color;
  }
}

.horizontal-rotate {
  rotate: 180deg;
}

